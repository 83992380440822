import { uploadToFirestoreForFeedback } from "../../utils/firebase.utils";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import FormInput from "../form-input/form-input.component";
import Button from "../button/button.component";

const defaultFormFields = {
  firstQuestion: "",
  secondQuestion: "",
  thirdQuestion: "",
  fourthQuestion: "",
  fifthQuestion: "",
};

const FeedBack = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const {
    firstQuestion,
    secondQuestion,
    thirdQuestion,
    fourthQuestion,
    fifthQuestion,
  } = formFields;

  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);
  const feedbackListTableName = "feedback";

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleFeedbackChange = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async (e) => {
    const isConfirmed = window.confirm("Ready to submit your feedback?");
    if (isConfirmed) {
      e.preventDefault();

      // Generate a unique ID for this submission
      const uid = uuidv4();

      const data = {
        uid,
        formFields,
      };

      try {
        // Call the uploadToFirestore function to upload the data
        await uploadToFirestoreForFeedback(data, feedbackListTableName, uid);
        console.log("Email with UID uploaded to Firestore");
        // Set joinedSuccessfully to true upon successful submission
        setSubmittedSuccessfully(true);
        // Reset the email state after successful submission
        resetFormFields();
      } catch (error) {
        console.error("Error uploading email to Firestore: ", error);
      }
    }
  };

  return (
    <div className="card-transparent" data-aos="fade-up">
      <h1 style={{ color: "black" }}>Feedback</h1>
      <h2 style={{ color: "black" }}>
        Does Gibby help you understand your benefits?
      </h2>
      <form onSubmit={handleSubmit}>
        <FormInput
          label=""
          type="text"
          required
          onChange={handleFeedbackChange}
          name="firstQuestion"
          value={firstQuestion}
        />
        <h2 style={{ color: "black" }}>
          Did you understand your insurance before Gibby?
        </h2>
        <FormInput
          label=""
          type="text"
          required
          onChange={handleFeedbackChange}
          name="secondQuestion"
          value={secondQuestion}
        />
        <h2 style={{ color: "black" }}>
          {" "}
          Would you use Gibby for your insurance needs?
        </h2>
        <FormInput
          label=""
          type="text"
          required
          onChange={handleFeedbackChange}
          name="thirdQuestion"
          value={thirdQuestion}
        />
        <h2 style={{ color: "black" }}>
          What would make Gibby more effective in servicing your insurance
          needs?
        </h2>
        <FormInput
          label=""
          type="text"
          required
          onChange={handleFeedbackChange}
          name="fourthQuestion"
          value={fourthQuestion}
        />
        <h2 style={{ color: "black" }}>
          Would you share Gibby with a friend if they were having insurance
          questions/problems?
        </h2>
        <FormInput
          label=""
          type="text"
          required
          onChange={handleFeedbackChange}
          name="fifthQuestion"
          value={fifthQuestion}
        />
        {submittedSuccessfully ? (
          <p style={{ color: "black" }}>Your Feedback was Submitted!</p>
        ) : (
          <Button type="submit">Submit</Button>
        )}
      </form>
    </div>
  );
};

export default FeedBack;
