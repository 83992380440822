const parseSob = (genText) => {
  const sobMatch = genText.match(/(?:^|\b)summary\s*of\s*benefits\s*(.+?)\./i);
  if (sobMatch) {
    return "true";
  }
  return "false";
};

const parseEob = (genText) => {
  const eobMatch = genText.match(
    /(?:^|\b)explanation\s*of\s*benefits\s*([^.]*)\./i
  );
  if (eobMatch) {
    return "true";
  }
  return "false";
};

const parseInvoice = (genText) => {
  const invoiceMatch = genText.match(/(?:^|\b)This is an invoice (.+?)\./i);
  if (invoiceMatch) {
    return "true";
  }
  return "false";
};

// Function to parse the company name from the generated text
const parseCompanyName = (genText) => {
  const companyMatch = genText.match(/(?:^|\b)company name is (.+?)\./i);
  if (companyMatch) {
    return companyMatch[1].trim();
  }

  const companyMatchAlt = genText.match(/(?:^|\b)company called(.+?)\./i);
  if (companyMatchAlt) {
    return companyMatchAlt[1].trim();
  }

  return "N/A";
};

// Function to parse the amount from the generated text
// const parseAmount = (genText) => {
//   let finalAmount = null;
//   const amountRegex = /(?:^|\b)You may pay your provider\s+\$([\d,]+(?:\.\d{2})?)\./;
//   const altAmountRegex = /(?:^|\b)total amount is\$([\d,]+(?:\.\d{2})?)\./;
//   const altAmountRegexTwo = /(?:^|\b)The amount you may pay your provider is\s+\$([\d,]+(?:\.\d{2})?)\./;

//   const amountMatch = genText.match(amountRegex);
//   if (amountMatch) {
//     const amountWithoutCommas = amountMatch[1].replace(/,/g, "");
//     const parsedAmount = parseFloat(amountWithoutCommas);
//     if (finalAmount === null || parsedAmount < finalAmount) {
//       finalAmount = parsedAmount;
//     }
//   }

//   const altAmountMatch = genText.match(altAmountRegex);
//   if (altAmountMatch) {
//     const altAmountWithoutCommas = altAmountMatch[1].replace(/,/g, "");
//     const altParsedAmount = parseFloat(altAmountWithoutCommas);
//     if (finalAmount === null || altParsedAmount < finalAmount) {
//       finalAmount = altParsedAmount;
//     }
//   }

//   const altAmountMatchTwo = genText.match(altAmountRegexTwo);
//   if (altAmountMatchTwo) {
//     const altAmountWithoutCommas = altAmountMatchTwo[1].replace(/,/g, "");
//     const altParsedAmountTwo = parseFloat(altAmountWithoutCommas);

//     if (finalAmount === null || altParsedAmountTwo < finalAmount) {
//       finalAmount = altParsedAmountTwo;
//     }
//   }

//   if (finalAmount !== null) {
//     return `$${finalAmount.toFixed(2)}`;
//   }

//   return finalAmount;
// };

const parseAmount = (genText) => {
  let finalAmount = null;
  let finalAmountOwed = null;

  const amountRegex = /(?:^|\b)TOTAL\s+\$([\d,]+(?:\.\d{2})?)\b/;
  const amountMatch = genText.match(amountRegex);
  if (amountMatch) {
    const amountWithoutCommas = amountMatch[1].replace(/,/g, "");
    finalAmount = parseFloat(amountWithoutCommas);
  }

  const amountOwedRegex = /(?:^|\b)What\s+I\s+Owe\s+\$([\d,]+(?:\.\d{2})?)\b/;
  const amountOwedMatch = genText.match(amountOwedRegex);
  if (amountOwedMatch) {
    const amountOwedWithoutCommas = amountOwedMatch[1].replace(/,/g, "");
    finalAmountOwed = parseFloat(amountOwedWithoutCommas);
  }

  if (finalAmount !== null) {
    return `$${finalAmount.toFixed(2)}`;
  } else if (finalAmountOwed !== null) {
    return `$${finalAmountOwed.toFixed(2)}`;
  } else {
           return finalAmount;
         }
};

// Function to parse the deductible from the generated text
const parseDeductible = (genText) => {
  const deductibleMatch = genText.match(/(?:^|\b)deductible is \$([\d,.]+)/i);

  if (deductibleMatch) {
    return `$${deductibleMatch[1]}`;
  }
  return "N/A";
};

// Function to parse the out-of-pocket maximum from the generated text
const parseOutOfPocketMax = (genText) => {
  let outOfPocketMax = "";
  const outOfPocketMaxMatch = genText.match(
    /(?:^|\b)out-of-pocket maximum is \$([\d,.]+)/i
  );

  const outOfPocketMaxMatchAlt = genText.match(
    /(?:^|\b)out of pocket maximum is \$([\d,.]+)/i
  );

  outOfPocketMax =
    outOfPocketMaxMatch !== null
      ? `$${outOfPocketMaxMatch[1]}`
      : outOfPocketMaxMatchAlt !== null
      ? `$${outOfPocketMaxMatchAlt[1]}`
      : "";
  return outOfPocketMax;
};

// Function to replace sensitive information with placeholders
const maskPersonalInfo = (text, currentUser) => {
  // Construct a regex pattern for the user's full name
  const namePattern = new RegExp(
    currentUser.fullName.replace(/\s+/g, "\\s*"),
    "gi"
  );

  // Define a regex pattern for the address
  const addressPattern = /\b\d{1,3}\s[A-z]+\s[A-z]+/g; // Simple address pattern: number, street, city

  // Replace names and addresses with placeholders
  text = text.replace(namePattern, "PERSON_NAME");
  text = text.replace(addressPattern, "ADDRESS");

  return text;
};

export const parseGeneratedTextForDocument = (genText) => {
  console.log("parseGeneratedTextForDocument() >> " + genText);
  // Mask personal information in the generated text
  // genText = maskPersonalInfo(genText, currentUser);

  // Parse other document information as before
  const createdAt = new Date().toISOString();
  const isSob = parseSob(genText);
  const isEob = parseEob(genText);
  const isInvoice = parseInvoice(genText);
  const company = parseCompanyName(genText);
  const amount = parseAmount(genText);
  const deductible = parseDeductible(genText);
  const outOfPocketMax = parseOutOfPocketMax(genText);

  return {
    createdAt,
    isSob,
    isEob,
    isInvoice,
    company,
    amount,
    deductible,
    outOfPocketMax,
    text: genText,
  };
};

