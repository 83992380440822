import React, { createContext, useContext, useState, useEffect } from "react";

// Create a context for the theme
const ThemeContext = createContext();

// Create a provider component for the theme
export const ThemeProvider = ({ children }) => {
  // Load theme preference from local storage or default to light mode
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("isDarkMode") === "true"
  );

  // Function to toggle between light and dark mode
  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      // Save the mode preference to local storage
      localStorage.setItem("isDarkMode", newMode);
      return newMode;
    });
  };

  // Effect to set the HTML body class based on the current theme
  useEffect(() => {
    document.body.classList.toggle("dark-mode", isDarkMode);
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Custom hook to consume the theme context
export const useTheme = () => {
  return useContext(ThemeContext);
};
