import "./homecardlist.styles.scss";
import Button from "../button/button.component";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import barchart from "../../img/home-barchart.jpg";
import barchartdark from "../../img/home-barchart-dark.jpg";
import submitdoc from "../../img/home-submitdoc.jpg";
import submitdocdark from "../../img/home-submitdoc-dark.jpg";
import chatbox from "../../img/home-chat.jpg";
import chatboxdark from "../../img/home-chat-dark.jpg";
import summarytable from "../../img/home-table.jpg";
import summarytabledark from "../../img/home-table-dark.jpg";
import Waitlist from "../waitlist/waitlist.component";
import { useTheme } from "../../contexts/theme.context";
import gibbydemo from "../../img/gibby-demo.gif";

const HomeCardList = () => {
  let navigate = useNavigate();

  const routeChange = () => {
    let path = `/about`;
    navigate(path);
  };
  const { isDarkMode } = useTheme();

  return (
    <Fragment>
      <div className="card-list-alt">
        <Waitlist />
        <div className="card-transparent">
          <div className="card-transparent">
            <img
              src={gibbydemo}
              alt="Demo GIF"
              style={{ width: "100%", height: "auto", borderRadius: "10px" }}
            />
          </div>
          <div>
            <h2 style={{ fontSize: "30px" }}>
              Your Personal Insurance Assistant Powered by AI
            </h2>
            <p style={{ fontSize: "20px" }}>
              Gibby makes it easy to navigate all your insurance needs
            </p>
            <div>
              <Button onClick={routeChange}>Learn More</Button>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="flex-container">
            <div>
              {isDarkMode ? (
                <img
                  src={summarytabledark}
                  alt="home summary table dark"
                  className="image-container-center"
                />
              ) : (
                <img
                  src={summarytable}
                  alt="home summary table"
                  className="image-container-center"
                />
              )}
            </div>
            <div>
              {isDarkMode ? (
                <img
                  src={barchartdark}
                  alt="home bar chart dark"
                  className="image-container-center"
                />
              ) : (
                <img
                  src={barchart}
                  alt="home bar chart"
                  className="image-container-center"
                />
              )}
            </div>
          </div>
        </div>

        <div className="card">
          <div className="flex-container">
            {isDarkMode ? (
              <img
                src={submitdocdark}
                alt="home submit doc dark"
                className="image-container-right"
              />
            ) : (
              <img
                src={submitdoc}
                alt="home submit"
                className="image-container-right"
              />
            )}
            <div>
              <h2>It’s as easy as 123</h2>
              <p style={{ fontSize: "20px" }}>
                Simply upload your summary of benefits, explanation of benefits,
                and your invoice and watch Gibby do the work for you
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="flex-container">
            <div>
              <h2>Have more questions?</h2>
              <p style={{ fontSize: "20px" }}>
                Start a conversation with Gibby and ask away
              </p>
            </div>
            <div>
              {isDarkMode ? (
                <img
                  src={chatboxdark}
                  alt="home chat box dark"
                  className="image-container-left"
                />
              ) : (
                <img
                  src={chatbox}
                  alt="home chat box"
                  className="image-container-left"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HomeCardList;
