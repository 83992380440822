// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  setDoc,
  collection,
  writeBatch,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { encryptData, decryptData, encryptionKey } from "./encryption.utils";

const firebaseConfig = {
  apiKey: "AIzaSyByPGzYUbjpkb2AzrheVGXX1HJmXa2-X-M",
  authDomain: "tir-db.firebaseapp.com",
  projectId: "tir-db",
  storageBucket: "tir-db.appspot.com",
  messagingSenderId: "740037111615",
  appId: "1:740037111615:web:5b6b5d041a5ba3dc206d8a",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);

export const storage = getStorage(firebaseApp);

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: "select_account",
});

export const auth = getAuth();

export const signInWithGooglePopup = () =>
  signInWithPopup(auth, googleProvider);

export const signInWithGoogleRedirect = () =>
  signInWithRedirect(auth, googleProvider);

export const addCollectionAndDocuments = async (
  collectionKey,
  objectsToAdd
) => {
       const collectionRef = collection(db, collectionKey);
       const batch = writeBatch(db);

       objectsToAdd.forEach((object) => {
         const docRef = doc(collectionRef, object.title.toLowerCase());
         batch.set(docRef, object);
       });

       await batch.commit();
       // console.log("done");
     };

export const createUserDocumentFromAuth = async (
  userAuth,
  additionalInformation = {}
) => {
  if (!userAuth) return;

  const userDocRef = doc(db, "users", userAuth.uid);

  const userSnapshot = await getDoc(userDocRef);

  if (!userSnapshot.exists()) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        createdAt,
        ...additionalInformation,
      });
    } catch (error) {
      console.error("error creating the user", error.message);
    }
  }

  return userDocRef;
};

export const createAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = (callback) =>
  onAuthStateChanged(auth, callback);

export const fetchUserData = async (tableName, user, setUserData) => {
  try {
    const collectionRef = collection(db, tableName); // Construct the collection reference
    const docRef = doc(collectionRef, user.uid); // Construct the document reference
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      // Document exists, fetch its data
      const userData = docSnapshot.data();
      setUserData(userData);
      // console.log("Fetched user data:", userData);
    } else {
      // console.log("No user data found");
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

export const fetchTableData = async (
  tableName,
  currentUser,
  setTableData,
  setChartData
) => {
  try {
    if (!tableName || !currentUser || !encryptionKey) {
      console.error("Error fetching table data: Invalid parameters");
      return;
    }

    const querySnapshotForDocument = await getDocs(
      collection(db, tableName, `${currentUser.uid}`, "documentData")
    );

    const newDocumentData = [];

    if (!querySnapshotForDocument.empty) {
      querySnapshotForDocument.forEach((doc) => {
        const decryptedData = decryptData(doc.data(), encryptionKey);
        if (decryptedData) {
          newDocumentData.push(decryptedData);
        }
      });

      // Sort newDocumentData by createdAt
      newDocumentData.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateA - dateB;
      });

      setTableData(newDocumentData);
      setChartData(newDocumentData);
      // console.log("Fetched new table data");
    }
  } catch (error) {
    console.error("Error fetching table data:", error);
  }
};

export const fetchDocumentData = async (
  tableName,
  currentUser,
  setDocumentData
) => {
  try {
    if (!tableName || !currentUser || !encryptionKey) {
      console.error("Error fetching table data: Invalid parameters");
      return;
    }

    const querySnapshotForDocument = await getDocs(
      collection(db, tableName, `${currentUser.uid}`, "documentData")
    );

    const newDocumentData = [];

    if (!querySnapshotForDocument.empty) {
      querySnapshotForDocument.forEach((doc) => {
        const decryptedData = decryptData(doc.data(), encryptionKey);
        if (decryptedData) {
          newDocumentData.push(decryptedData);
        }
      });

      // Sort newDocumentData by createdAt
      newDocumentData.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateA - dateB;
      });

      setDocumentData(newDocumentData);
      // console.log("Fetched new table data");
    }
  } catch (error) {
    console.error("Error fetching table data:", error);
  }
};

export const uploadToFirestoreForUser = async (
  data,
  tableName,
  nestedTableName,
  user
) => {
  try {
        if (!user || !user.uid) {
          console.error("User object or UID is undefined");
          return;
        }

        // Encrypt the data using the provided encryption key
        const encryptedData = encryptData(data, encryptionKey);

        const newRowRef = doc(
          collection(db, tableName, `${user.uid}`, nestedTableName)
        );

        // Get the ID of the new document
        const newDocumentId = await newRowRef.id;

        // Update data with the document ID
        encryptedData.id = newDocumentId;

        // Upload encrypted data to Firestore
        await setDoc(newRowRef, encryptedData);
      } catch (error) {
    console.error("Error adding document: ", error);
  }
};


export const uploadToFirestoreForWaitlist = async (
  data,
  tableName,
  nestedTableName
) => {
  const docRef = doc(db, tableName, nestedTableName);
  const emailSnapshot = await getDoc(docRef);

  if (!emailSnapshot.exists()) {
    const { email } = data;
    const createdAt = new Date();

    try {
      await setDoc(docRef, {
        email,
        createdAt,
      });
    } catch (error) {
      console.error("error creating add email", error.message);
    }
  }
};


export const uploadToFirestoreForFeedback = async (
  data,
  tableName,
  nestedTableName
) => {
  const docRef = doc(db, tableName, nestedTableName);

  const { uid, formFields } = data;
  const createdAt = new Date();

  console.log(formFields);

  try {
    await setDoc(docRef, {
      createdAt,
      uid,
      formFields,
    });
  } catch (error) {
    console.error("error creating add feedback", error.message);
  }
};

// Function to update data in Firestore
export const updateFirestoreData = async (rowData, user) => {
  try {
    if (!user || !user.uid) {
      console.error("User object or UID is undefined");
      return;
    }

    // Encrypt the updated data
    const encryptedData = encryptData(rowData, encryptionKey);

    // Get the document reference for the row
    const rowRef = doc(
      collection(db, "dashboardData", user.uid, "documentData"),
      rowData.id // Assuming rowData.id is the document ID
    );

    // Update the document with the new data
    await setDoc(rowRef, encryptedData);
    console.log("Document updated successfully");
  } catch (error) {
    console.error("Error updating document: ", error);
  }
};