import { Fragment, useContext, useEffect, useState } from "react";
import { fetchUserData } from "../../utils/firebase.utils";
import { Outlet, Link } from "react-router-dom";
import { UserContext } from "../../contexts/user.context";
import gibbylogo from "../../img/gibby-logo-inverted.png";
import lightbulb from "../../img/light-bulb.png";
import "./navigation.styles.scss";

const Navigation = ({ toggleDarkMode, isDarkMode }) => {
  const { currentUser } = useContext(UserContext);
  const [userData, setUserData] = useState([]);
  const userTableName = "users";

  useEffect(() => {
    if (!currentUser) return;
    fetchUserData(userTableName, currentUser, setUserData);
  }, [currentUser]);

  return (
    <Fragment>
      <div className="navigation">
        <Link to="/">
          <img
            className="logo-container"
            src={gibbylogo}
            alt="gibby-logo"
          ></img>
        </Link>
        <div className="nav-links-container">
          <Link className="nav-link" to="/about">
            {currentUser ? null : "ABOUT"}
          </Link>
          {currentUser ? (
            <Link className="nav-link" to="/auth">
              {currentUser ? userData.displayName : "HOME"}
            </Link>
          ) : (
            <Link className="nav-link" to="/auth">
              LOGIN
            </Link>
          )}
          {/* Button to toggle dark mode */}
          <Link className="nav-link" onClick={toggleDarkMode}>
            <img
              className="nav-icon-container"
              src={lightbulb}
              alt="light-logo"
            ></img>
          </Link>
        </div>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default Navigation;
