import React, { useState, useEffect, useContext, useMemo } from "react";
import { UserContext } from "../../contexts/user.context";
import { pdfjs } from "react-pdf";
import { createWorker } from "tesseract.js";
import { Helmet } from "react-helmet";
import { uploadToFirestoreForUser } from "../../utils/firebase.utils";
import { parseGeneratedTextForDocument } from "../../utils/parsing.utils";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

const UploadPDF = ({ setTableData, setChartData, loading, setLoading }) => {
  const { currentUser } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [textSections, setTextSections] = useState("");
  const dashboardTableName = "dashboardData";
  const nestedTableDataName = "documentData";

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc =
      "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js";
  }, []);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    clearResults();
    await convertFile(file);
  };

  const clearResults = () => {
    setTextSections("");
    setError(null);
  };

  const convertFile = async (file) => {
    setLoading(true);
    try {
      const [pdf, worker] = await Promise.all([loadFile(file), createWorker()]);
      const images = await convertToImage(pdf);
      const texts = await convertToText(worker, images);
      const textString = texts.join("\n");
      setTextSections(textString);
      await updateTableData(textString, currentUser);
    } catch (error) {
      setError(error);
    } finally {
      window.alert("Successfully uploaded document!");
      window.location.reload();
      setLoading(false);
    }
  };

  const loadFile = async (file) => {
    const fileData = await readFile(file);
    return pdfjs.getDocument({ data: fileData }).promise;
  };

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = (event) =>
        resolve(new Uint8Array(event.target.result));
      reader.readAsArrayBuffer(file);
    });
  };

  const convertToImage = async (pdf) => {
    const images = [];
    for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
      const page = await pdf.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = document.createElement("canvas");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      }).promise;
      images.push(canvas.toDataURL("image/png"));
    }
    return images;
  };

  const convertToText = async (worker, images) => {
    await worker.loadLanguage("eng");
    await worker.initialize("eng");
    const texts = await Promise.all(
      images.map(async (image) => {
        const {
          data: { text },
        } = await worker.recognize(image);
        return text;
      })
    );
    return texts;
  };

  const updateTableData = async (genText, user) => {
    try {
      const parsedData = parseGeneratedTextForDocument(genText);
      await Promise.all([
        setTableData((prevData) => [...prevData, parsedData]),
        setChartData((prevData) => [...prevData, parsedData]),
        uploadToFirestoreForUser(
          parsedData,
          dashboardTableName,
          nestedTableDataName,
          user
        ),
      ]);
    } catch (error) {
      console.error("Error updating table data:", error);
      // Optionally, you can handle the error here, e.g., show a toast message
    }
  };

  return (
    <>
      <Helmet>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js"></script>
      </Helmet>
      <h1>Upload your document (.pdf)</h1>
      <h3>How it works</h3>
      <p style={{ color: "grey", fontSize: "15px" }}>
        1. Submit your Summary of Benefits
      </p>
      <p style={{ color: "grey", fontSize: "15px" }}>
        2. Submit your EOB (Explanation of Benefits)
      </p>
      <p style={{ color: "grey", fontSize: "15px" }}>
        3. Submit the invoice associated with your EOB
      </p>
      <p>Gibby can answer any questions about your benefits via chat</p>
      <div id="input-container">
        <div>
          <label id="file-input-label" htmlFor="file-input">
            Select your pdf:
          </label>
          <br />
          <input
            type="file"
            id="file-input"
            name="file-input"
            multiple
            onChange={handleFileChange}
          />
        </div>
      </div>
      {loading && <div id="loading">Loading...</div>}
      {error && <div id="error">Error: {error.message}</div>}
      <div id="card-dash">
        <p>{textSections}</p>
      </div>
    </>
  );
};

export default UploadPDF;
