import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";

const BarChart = ({ chartData, chartRef }) => {
  const [deductibleMet, setDeductibleMet] = useState(false);
  const [outOfPocketMaxMet, setOutOfPocketMaxMet] = useState(false);
  const [deductibleValue, setDeductibleValue] = useState(null);
  const [outOfPocketMaxValue, setOutOfPocketMaxValue] = useState(null);

  useEffect(() => {
    if (chartData && chartData.length > 0) {
                                             // Sort chartData by createdAt
                                             const sortedChartData = [
                                               ...chartData,
                                             ].sort((a, b) => {
                                               const dateA = new Date(
                                                 a.createdAt
                                               );
                                               const dateB = new Date(
                                                 b.createdAt
                                               );
                                               return dateA - dateB;
                                             });

                                             let initialDeductibleValue = null;
                                             let initialOutOfPocketMaxValue = null;

                                             const firstChartData =
                                               sortedChartData[0];

                                             if (
                                               firstChartData.deductible &&
                                               firstChartData.outOfPocketMax
                                             ) {
                                               initialDeductibleValue = parseFloat(
                                                 firstChartData.deductible
                                                   .replace("$", "")
                                                   .replace(",", "")
                                               );
                                               initialOutOfPocketMaxValue = parseFloat(
                                                 firstChartData.outOfPocketMax
                                                   .replace("$", "")
                                                   .replace(",", "")
                                               );
                                             }

                                             let newDeductibleValue = initialDeductibleValue;
                                             let newOutOfPocketMaxValue = initialOutOfPocketMaxValue;

                                             // Loop through chart data and update deductibleValue and outOfPocketMaxValue
                                             sortedChartData.forEach((data) => {
                                               if (data.amount) {
                                                 const amount = parseFloat(
                                                   data.amount
                                                     .replace("$", "")
                                                     .replace(",", "")
                                                 );
                                                 if (!isNaN(amount)) {
                                                   newDeductibleValue -= amount;
                                                   newOutOfPocketMaxValue -= amount;
                                                   // Check if deductibleValue or outOfPocketMaxValue reached zero
                                                   if (
                                                     newDeductibleValue <= 0 &&
                                                     !deductibleMet
                                                   ) {
                                                     setDeductibleMet(true);
                                                   }
                                                   if (
                                                     newOutOfPocketMaxValue <=
                                                       0 &&
                                                     !outOfPocketMaxMet
                                                   ) {
                                                     setOutOfPocketMaxMet(true);
                                                   }
                                                 }
                                               }
                                             });

                                             if (newDeductibleValue < 0) {
                                               newDeductibleValue = 0;
                                             }

                                             if (newOutOfPocketMaxValue < 0) {
                                               newOutOfPocketMaxValue = 0;
                                             }

                                             setDeductibleValue(
                                               newDeductibleValue
                                             );
                                             setOutOfPocketMaxValue(
                                               newOutOfPocketMaxValue
                                             );
                                           }
  }, [chartData, deductibleMet, outOfPocketMaxMet]);

  useEffect(() => {
    const ctx = document.getElementById("barChart");

    if (ctx) {
               // Clear any existing chart
               if (chartRef.current) {
                 chartRef.current.destroy();
               }

               // Check if deductibleValue and outOfPocketMaxValue are valid numbers
               if (!isNaN(deductibleValue) && !isNaN(outOfPocketMaxValue)) {
                 chartRef.current = new Chart(ctx, {
                   type: "bar",
                   data: {
                     labels: ["Deductible", "Out of Pocket Maximum"],
                     datasets: [
                       {
                         label: "Deductible",
                         data: [deductibleValue, outOfPocketMaxValue],
                         backgroundColor: [
                           "rgba(255, 99, 132, 0.2)",
                           "rgba(35, 232, 84, 0.2)",
                         ],
                         borderColor: [
                           "rgba(255, 99, 132, 1)",
                           "rgba(35, 232, 84, 1)",
                         ],
                         borderWidth: 1,
                       },
                     ],
                   },
                   options: {
                     scales: {
                       y: {
                         type: "linear",
                         beginAtZero: true,
                       },
                     },
                   },
                 });
               }
             } else {
      console.error("Failed to create chart: canvas element not found");
    }
  }, [deductibleValue, outOfPocketMaxValue, chartRef]);

  return (
    <div>
      <div className="card">
        <div>
          {deductibleMet && (
            <div>
              <h3>Congrats. You've met your deductible! 🎉</h3>
            </div>
          )}
          <h2>
            Deductible: <p style={{ color: "red" }}>${deductibleValue}</p>
          </h2>
        </div>
        <div>
          {outOfPocketMaxMet && (
            <div>
              <h3>Congrats. You've met your Out of Pocket Maximum! 🎉</h3>
            </div>
          )}
          <h2>
            Out of Pocket Maximum:{" "}
            <p style={{ color: "green" }}>${outOfPocketMaxValue}</p>
          </h2>
        </div>
      </div>
      <div className="card-chart">
        <h3>Deductible vs Out-of-Pocket Maximum</h3>
        <canvas id="barChart" width="400" height="200"></canvas>
      </div>
      <div className="card-values"></div>
    </div>
  );
};

export default BarChart;
