const DashboardGreeting = ({ userData, currentDay }) => {
  return (
    <div>
      <h1 style={{ color: "white", padding: "10px" }}>Dashboard</h1>
      <div className="card">
        <h2
          style={{
            color: "grey",
            fontSize: "20px",
          }}
        >
          Hey {userData.displayName}, Happy {currentDay}!
        </h2>
      </div>
    </div>
  );
};

export default DashboardGreeting;
