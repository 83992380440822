import { updateFirestoreData } from "../../utils/firebase.utils";
import { useState, useEffect } from "react";
import "./summary-table.styles.scss";

const SummaryTable = ({
  tableData,
  setTableData,
  setChartData,
  deleteTableRow,
  currentUser,
}) => {
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [payNowValues, setPayNowValues] = useState([]); // Store payNow values

  useEffect(() => {
    // Compute payNow values
    const newPayNowValues = [];
    for (let i = 0; i < tableData.length; i++) {
      if (i === 0) {
        newPayNowValues.push("N/A");
      } else {
        newPayNowValues.push(
          tableData[i].amount < tableData[i - 1].amount ? "❌" : "✅"
        );
      }
    }
    setPayNowValues(newPayNowValues);
  }, [tableData]);

  const handleDelete = (id) => {
    deleteTableRow(id);
  };

  const handleEdit = (index, rowData) => {
    setEditableRowIndex(index);
    setEditedData(rowData);
  };

    const handleLearnMore = (amount) => {
      alert(
        `Do not pay $${amount} because there is a discrepancy between the invoice and explanation of benefits.`
      );
    };

    const handleSave = async (index) => {
      try {
        // Update Firestore data
        await updateFirestoreData(editedData, currentUser);

        // Create a copy of editedData to avoid modifying state directly
        const updatedEditedData = { ...editedData };

        // Update the tableData prop with the editedData
        const updatedTableData = [...tableData];
        updatedTableData[index] = updatedEditedData;
        setTableData(updatedTableData);

        // Update bar chart data
        setChartData(updatedTableData);

        // Reset editableRowIndex
        setEditableRowIndex(null);
      } catch (error) {
        console.error("Error saving data: ", error);
      }
    };
    const handleChange = (e, field) => {
      if (field !== "type") {
        setEditedData((prevData) => ({
          ...prevData,
          [field]: e.target.value,
        }));
        return;
      }

      let value;
      switch (e.target.value) {
        case "SOB":
          value = {
            isSob: true,
            isEob: false,
            isInvoice: false,
          };
          break;
        case "EOB":
          value = {
            isSob: false,
            isEob: true,
            isInvoice: false,
          };
          break;
        case "Invoice":
          value = {
            isSob: false,
            isEob: false,
            isInvoice: true,
          };
          break;
        default:
          value = {
            isSob: false,
            isEob: false,
            isInvoice: false,
          };
      }

      setEditedData((prevData) => ({
        ...prevData,
        ...value,
        [field]: e.target.value,
      }));
    };

    return (
      <div className="card-table-wrapper">
        <h3 style={{ color: "white" }}>Summary</h3>
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Document</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Deductible</th>
                <th>Out of Pocket</th>
                <th>Pay Now</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((data, index) => (
                <tr key={index}>
                  <td>
                    {editableRowIndex === index ? (
                      <div className="input-container">
                        <input
                          type="text"
                          value={editedData.company || ""}
                          onChange={(e) => handleChange(e, "company")}
                        />
                      </div>
                    ) : data.company === "" ? (
                      "N/A"
                    ) : (
                      data.company
                    )}
                  </td>
                  <td>
                    {editableRowIndex === index ? (
                      <div className="input-container">
                        <select
                          value={editedData.type || ""}
                          onChange={(e) => handleChange(e, "type")}
                        >
                          <option value="">Select Type</option>
                          <option value="SOB">Summary of Benefits (SOB)</option>
                          <option value="EOB">
                            Explanation of Benefits (EOB)
                          </option>
                          <option value="Invoice">Invoice</option>
                        </select>
                      </div>
                    ) : data.isSob === "true" ? (
                      "Summary of Benefits (SOB)"
                    ) : data.isEob === "true" ? (
                      "Explanation of Benefits (EOB)"
                    ) : data.isInvoice === "true" ? (
                      "Invoice"
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>
                    {editableRowIndex === index ? (
                      <div className="input-container">
                        <input
                          type="number"
                          value={editedData.amount || ""}
                          onChange={(e) => handleChange(e, "amount")}
                        />
                      </div>
                    ) : data.amount === null ? (
                      "N/A"
                    ) : (
                      data.amount
                    )}
                  </td>
                  <td>
                    {editableRowIndex === index ? (
                      <div className="input-container">
                        <input
                          type="number"
                          value={editedData.deductible || ""}
                          onChange={(e) => handleChange(e, "deductible")}
                        />
                      </div>
                    ) : data.deductible === null ? (
                      "N/A"
                    ) : (
                      data.deductible
                    )}
                  </td>
                  <td>
                    {editableRowIndex === index ? (
                      <div className="input-container">
                        <input
                          type="number"
                          value={editedData.outOfPocketMax || ""}
                          onChange={(e) => handleChange(e, "outOfPocketMax")}
                        />
                      </div>
                    ) : data.outOfPocketMax === null ? (
                      "N/A"
                    ) : (
                      data.outOfPocketMax
                    )}
                  </td>
                  <td>
                    {editableRowIndex === index ? (
                      <div className="input-container">
                        <input
                          type="text"
                          value={payNowValues[index] || ""}
                          disabled
                        />
                      </div>
                    ) : (
                      payNowValues[index]
                    )}
                  </td>
                  <td>
                    <button
                      onClick={() => handleSave(index)}
                      style={{
                        display: editableRowIndex === index ? "block" : "none",
                      }}
                    >
                      Save
                    </button>
                    {!editableRowIndex && (
                      <button onClick={() => handleEdit(index, data)}>
                        Edit
                      </button>
                    )}
                    <button onClick={() => handleDelete(data.id)}>
                      Delete
                    </button>
                    {/* {payNowValues[index] === "❌" && (
                      <button onClick={() => handleLearnMore(data.amount)}>
                        Learn More
                      </button>
                    )} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
};

export default SummaryTable;
