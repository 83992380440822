import { uploadToFirestoreForWaitlist } from "../../utils/firebase.utils";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import FormInput from "../form-input/form-input.component";
import Button from "../button/button.component";

const Waitlist = () => {
  const [email, setEmail] = useState("");
  const [joinedSuccessfully, setJoinedSuccessfully] = useState(false);
  const waitListTableName = "waitlist";

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if email is not empty before joining
    if (email.trim() === "") {
      // Handle the case where email is empty
      alert("Email is required. Please enter your email address.");
      return; // Prevent further execution of the function
    }

    // Check if the entered email matches the regular expression
    if (!emailRegex.test(email)) {
      // Handle the case where email format is invalid
      alert("Email is not valid. Please enter a valid email address.");
      return; // Prevent further execution of the function
    }

    // Generate a unique ID for this submission
    const uid = uuidv4();

    // Prepare data to upload to Firestore
    const data = {
      email,
      uid,
    };

    try {
      // Call the uploadToFirestore function to upload the data
      await uploadToFirestoreForWaitlist(data, waitListTableName, uid);
      console.log("Email with UID uploaded to Firestore");
      // Set joinedSuccessfully to true upon successful submission
      setJoinedSuccessfully(true);
      // Reset the email state after successful submission
      setEmail("");
    } catch (error) {
      console.error("Error uploading email to Firestore: ", error);
    }
  };

  return (
    <div className="card-transparent">
      <h1>Join the waitlist.</h1>
      <FormInput
        label="email"
        type="email"
        required
        onChange={handleEmailChange}
        name="email"
        value={email}
      />
      {/* Conditionally render "Successfully joined" */}
      {joinedSuccessfully ? (
        <p>Successfully Joined!</p>
      ) : (
        <Button type="submit" onClick={handleSubmit}>
          JOIN
        </Button>
      )}
    </div>
  );
};

export default Waitlist;
