import { Routes, Route } from "react-router-dom";
import Home from "./routes/home/home.component";
import Navigation from "./routes/navigation/navigation.component";
import Authentication from "./routes/authentication/authentication.component";
import AOS from "./scripts";
import "./components/home-banner/home-banner.styles.scss";
import "./components/button/button.styles.scss";
import "./components/directory/directory.styles.scss";
import About from "./components/about/about.component";
import { useLocation } from "react-router-dom";
import { useTheme } from "./contexts/theme.context";

const App = () => {
  AOS();
  const location = useLocation();
  const { toggleDarkMode, isDarkMode } = useTheme(); // Get the toggleDarkMode function from the context

  const isAuthenticationRoute = location.pathname.startsWith("/auth");
  return (
    <>
      {!isAuthenticationRoute && (
        <Navigation toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />
      )}
      <Routes>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="auth" element={<Authentication />} />
      </Routes>
    </>
  );
};;

export default App;
