import loadingGif from "../../img/loading-spinner.gif";

const LoadingComponent = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh", // Full height of the viewport
    }}
  >
    <h1 style={{ marginBottom: "20px", color: "white" }}>Loading..</h1>
    <img
      src={loadingGif}
      alt="loading GIF"
      style={{ width: "100px", height: "50px" }}
    />
  </div>
);

export default LoadingComponent;
