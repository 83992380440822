import Directory from "../../components/directory/directory.component";
import Footer from "../../components/footer/footer.component";
import { useContext } from "react";
import { UserContext } from "../../contexts/user.context";
import Dashboard from "../../components/dashboard/dashboard.component";

const Home = () => {
  const { currentUser } = useContext(UserContext);

  return (
    <div>
      {currentUser ? <Dashboard /> : <Directory />}
      <Footer />
    </div>
  );
};

export default Home;
