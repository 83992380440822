import { useState, useRef } from "react";
import "./chat-ui.styles.scss";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { genAI } from "../../utils/gemini.utils";
import gibbylogo from "../../img/gibby-logo.png";
import Button from "../button/button.component";
// import glasses from "../../img/glasses-inverted.png";
// import person from "../../img/person-icon-inverted.png";
import "../../routes/navigation/navigation.styles.scss";
import {
  ConversationHeader,
  AttachmentButton,
  SendButton,
  Avatar,
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";

const ChatUi = ({ userData, documentData }) => {
  const inputRef = useRef();
  var model = null;
  // State to manage the typing indicator of the chatbot
  const [isChatbotTyping, setIsChatbotTyping] = useState(false);
  const [msgInputValue, setMsgInputValue] = useState("");
  const [file, setFile] = useState();
  const [fileUploaded, setFileUploaded] = useState(false);
  const fileInputRef = useRef(null);

  function uploadImage(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setFileUploaded(true);
  }

  function getModel(fileUploaded) {
    if (fileUploaded) {
      return genAI.getGenerativeModel({
        model: "gemini-pro-vision",
      });
    } else {
      return genAI.getGenerativeModel({
        model: "gemini-pro",
      });
    }
  }

  async function fileToGenerativePart(file) {
    const base64EncodedDataPromise = new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]);
      reader.readAsDataURL(file);
    });
    return {
      inlineData: {
        data: await base64EncodedDataPromise,
        mimeType: file.type,
      },
    };
  }

  // State to store chat messages
  const [chatMessages, setChatMessages] = useState([
    {
      message: `Hi ${userData.displayName}, I'm Gibby!`,
      sender: "Gibby",
    },
    {
      message:
        "Questions about your benefits or invoices? I can help you with that!",
      sender: "Gibby",
    },
  ]);

  // Function to handle user messages
  const handleUserMessage = async (userMessage) => {
    // Create a new user message object
    const documentText = documentData.map((item) => item.text).join(", ");

    const finalUserMessage =
      `Based on this information: ${documentText}, ` + userMessage;

    const newUserMessage = {
      message: userMessage,
      sender: "user",
      direction: "outgoing",
    };

    // Update chat messages state with the new user message
    const updatedChatMessages = [...chatMessages, newUserMessage];
    setChatMessages(updatedChatMessages);

    // Set the typing indicator for the chatbot
    setMsgInputValue("");
    inputRef.current.focus();
    setIsChatbotTyping(true);

    model = getModel(fileUploaded);

    // Process user message with ChatGPT
    await searchGoogle(updatedChatMessages, finalUserMessage, model);
  };

  async function searchGoogle(messages, query, model) {
    try {
      const fileInputEl = document.querySelector("input[type=file]");
      const imageParts = await Promise.all(
        [...fileInputEl.files].map(fileToGenerativePart)
      );

      var result = null;

      if (file == null) {
        result = await model.generateContent(query);
      } else {
        result = await model.generateContent([query, ...imageParts]);
      }

      const response = await result.response;
      const text = await response.text(); // Make sure to await the text() call
      console.log(text);

      if (text !== null) {
        setChatMessages([
          ...messages,
          {
            message: text,
            sender: "Gibby",
          },
        ]);
        setIsChatbotTyping(false);
      }
    } catch (error) {
      // Handle the error
      console.error("Error fetching response:", error);
      setChatMessages([
        ...messages,
        {
          message: "Sorry, I couldn't quite answer your question.",
          sender: "Gibby",
        },
      ]);
      setIsChatbotTyping(false);
    }
  }
  // Send the user message to ChatGPT API

  return (
    <>
      {/* A container for the chat window */}
      <div className="chatui-container" data-aos="fade-up">
        <MainContainer className="message-container" responsive>
          <ChatContainer>
            <ConversationHeader>
              <Avatar src={gibbylogo} name="Gibby" />
              <ConversationHeader.Content
                userName="Gibby"
                info="Your AI Assistant"
              />
            </ConversationHeader>
            {/* Display chat messages and typing indicator */}
            <MessageList
              typingIndicator={
                isChatbotTyping ? (
                  <TypingIndicator content="Gibby is typing..." />
                ) : null
              }
            >
              {/* Map through chat messages and render each message */}
              {chatMessages.map((message, i) => {
                return (
                  <Message
                    key={i}
                    model={message}
                    style={
                      message.sender === "Gibby"
                        ? {
                            textAlign: "left",
                          }
                        : {}
                    }
                  >
                    {/* <Avatar
src={message.sender === "Gibby" ? glasses : person}
/> */}
                  </Message>
                );
              })}
            </MessageList>
            {/* Input field for the user to type messages */}
            <div
              as={MessageInput}
              style={{
                display: "flex",
                flexDirection: "row",
                borderTop: "1px dashed #d1dbe4",
              }}
            >
              <MessageInput
                ref={inputRef}
                onChange={(msg) => setMsgInputValue(msg)}
                placeholder="Message"
                value={msgInputValue}
                sendButton={false}
                attachButton={false}
                onSend={handleUserMessage}
                style={{
                  flexGrow: 1,
                  borderTop: 0,
                  flexShrink: "initial",
                }}
              />
              <AttachmentButton onClick={() => fileInputRef.current.click()} />
              <input
                type="file"
                style={{ display: "none" }}
                onChange={uploadImage}
                ref={fileInputRef}
              />
              <SendButton
                onClick={() => handleUserMessage(msgInputValue)}
                disabled={msgInputValue.length === 0}
                style={{
                  fontSize: "1.2em",
                  marginLeft: 0,
                  paddingLeft: "0.2em",
                  paddingRight: "0.2em",
                }}
              />
            </div>
          </ChatContainer>
        </MainContainer>
      </div>
    </>
  );
};;;;

export default ChatUi;
