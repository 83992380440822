import React, { useEffect, useState, useRef, useContext } from "react";
import { UserContext } from "../../contexts/user.context";
import { db, fetchUserData, fetchTableData } from "../../utils/firebase.utils";
import Spinner from "../spinner/spinner.component";
import loadingGif from "../../img/loading-spinner.gif";
import { collection, doc, deleteDoc } from "firebase/firestore";
import "./dashboard.styles.scss";
import SubmitDoc from "../submit-doc/submit-doc.component";
import SummaryTable from "../summary-table/summary-table.component";
import BarChart from "../bar-chart/bar-chart.component";
import DashboardGreeting from "../dashboard-greeting/dashboard-greeting.component";
import UploadPDF from "../upload-pdf/upload-pdf.component";
import LoadingComponent from "../loading-spinner/loading-spinner.component";

const Dashboard = () => {
  const { currentUser } = useContext(UserContext);
  const chartRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [currentDay, setCurrentDay] = useState("");
  const [tableData, setTableData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [userData, setUserData] = useState([]);
  const dashboardTableName = "dashboardData";
  const userTableName = "users";

  // Get Date
  useEffect(() => {
    const today = new Date();
    const options = { weekday: "long" };
    setCurrentDay(today.toLocaleDateString(undefined, options));
  }, []);

  // Fetch Data
  useEffect(() => {
    if (!currentUser) return;
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([
        fetchTableData(
          dashboardTableName,
          currentUser,
          setTableData,
          setChartData
        ),
        fetchUserData(userTableName, currentUser, setUserData),
      ]);
      setLoading(false);
    };
    fetchData();
  }, [currentUser]);

  const deleteData = async (tableDataIdForDelete) => {
    try {
      const deleteConfirm = async () => {
        const tableDataRef = doc(
          collection(db, "dashboardData", `${currentUser.uid}`, "documentData"),
          tableDataIdForDelete
        );
        await deleteDoc(tableDataRef);
        setTableData((prevData) =>
          prevData.filter((data) => data.id !== tableDataIdForDelete)
        );
        setChartData((prevData) =>
          prevData.filter((data) => data.id !== tableDataIdForDelete)
        );
      };
      const isConfirmed = window.confirm(
        "Are you sure you want to delete this?"
      );
      if (isConfirmed) await deleteConfirm();
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  };

  return (
    <div className="dashboard-container">
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {tableData.length > 0 && (
            <div className="card-list">
              <DashboardGreeting userData={userData} currentDay={currentDay} />
              <SummaryTable
                tableData={tableData}
                setTableData={setTableData}
                setChartData={setChartData}
                deleteTableRow={deleteData}
                currentUser={currentUser}
              />
              <BarChart chartData={chartData} chartRef={chartRef} />
            </div>
          )}
          <div className="card-list">
            <div className="card">
              <UploadPDF
                setTableData={setTableData}
                setChartData={setChartData}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
            {/* <div className="card">
              <SubmitDoc setTableData={setTableData} setChartData={setChartData} setLoading={setLoading} />
            </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
