import { Fragment, useContext, useState } from "react";
import { UserContext } from "../../contexts/user.context";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SignUpForm from "../../components/sign-up/sign-up.component";
import SignInForm from "../../components/sign-in/sign-in.component";
import { signOutUser } from "../../utils/firebase.utils";
import Button from "../../components/button/button.component";
import "../../components/button/button.styles.scss";
import "../../components/directory/directory.styles.scss";
import Footer from "../../components/footer/footer.component";
import ChatUi from "../../components/chat-ui/chat-ui.component";
import gibbylogo from "../../img/gibby-logo-inverted.png";
import { useEffect } from "react";
import { fetchUserData, fetchDocumentData } from "../../utils/firebase.utils";
import "./authentication.styles.scss";
import FeedBack from "../../components/feedback/feedback.component";

const Authentication = () => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [userData, setUserData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [isFullScreenForChat, setIsFullScreenForChat] = useState(false);
  const [isFullScreenForFeedback, setIsFullScreenForFeedback] = useState(false);
  const dashboardTableName = "dashboardData";
  const userTableName = "users";

  useEffect(() => {
    if (!currentUser) return;
    fetchDocumentData(dashboardTableName, currentUser, setDocumentData);
    fetchUserData(userTableName, currentUser, setUserData);
  }, [currentUser, dashboardTableName, userTableName]);

  let navigate = useNavigate();

  const routeChangeHome = () => {
    let path = `/`;
    navigate(path);
  };

  const signOutHandler = async () => {
    // Ask for confirmation
    const isConfirmed = window.confirm("Are you sure you want to sign out?");

    // If user confirms, sign out
    if (isConfirmed) {
      await signOutUser();
      setCurrentUser(null);
      routeChangeHome();
    }
  };

  const openFullScreenForChat = () => {
    console.log(currentUser);
    setIsFullScreenForChat(true);
  };

  const closeFullScreenForChat = () => {
    setIsFullScreenForChat(false);
  };

  const openFullScreenForFeedback = () => {
    setIsFullScreenForFeedback(true);
  };

  const closeFullScreenForFeedback = () => {
    setIsFullScreenForFeedback(false);
  };

  return (
    <Fragment>
      {currentUser ? (
        <>
          <div className="authentication-container">
            <Link to="/">
              <img
                className="logo-container"
                src={gibbylogo}
                alt="gibby-logo"
              />
            </Link>
            <div className="card-list-alt">
              <div className="card">
                <div className="card-content">
                  <h2>Hi {userData.displayName}!</h2>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <Button onClick={routeChangeHome}>GO TO DASHBOARD</Button>
                  <Button onClick={openFullScreenForChat}>
                    START CONVERSATION
                  </Button>
                  <Button onClick={openFullScreenForFeedback}>FEEDBACK</Button>
                  {/* <Button onClick={routeChangeAbout}>GO TO ABOUT PAGE</Button> */}
                  <Button type="submit" onClick={signOutHandler}>
                    SIGN OUT
                  </Button>
                </div>
              </div>
            </div>
            <div className="app">
              {isFullScreenForChat && (
                <div className="full-screen-overlay">
                  <div className="full-screen-container">
                    <ChatUi userData={userData} documentData={documentData} />
                    <button
                      className="close-screen-button"
                      onClick={closeFullScreenForChat}
                    >
                      Back
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="app">
              {isFullScreenForFeedback && (
                <div className="full-screen-overlay">
                  <div className="full-screen-container">
                    <div className="card-transparent">
                      <FeedBack />
                    </div>
                    <button
                      className="close-screen-button"
                      onClick={closeFullScreenForFeedback}
                    >
                      Back
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <div className="authentication-container">
          <Link to="/">
            <img className="logo-container" src={gibbylogo} alt="gibby-logo" />
          </Link>
          <div className="card-list-alt">
            <SignInForm />
            <SignUpForm />
          </div>
          <Footer />
        </div>
      )}
    </Fragment>
  );
};

export default Authentication;
