import Footer from "../footer/footer.component";
import "./about.styles.scss";
import Waitlist from "../waitlist/waitlist.component";
import askgibby from "../../img/ask-gibby.png";

const About = () => {
  return (
    <div data-aos="fade-right" data-aos-duration="1000">
      <div className="card-list-alt">
        {/* <img
          className="about-image-container"
          src={askgibby}
          alt="ask-gibby"
        ></img> */}
        <div className="card-alt">
          <h1>
            Confused by your health benefits and stunned by a medical bill?
          </h1>
          <p>You’re not alone.</p>
          <p>
            Nearly half of Americans with private health insurance experienced
            difficulties navigating their coverage. This difficulty can lead
            people to avoid treatment all together - a failure in our health
            system.
          </p>
        </div>
        <div className="card-alt">
          <h1>When you have a question a problem, who can you ask?</h1>
          <p>
            The traditional model of seeking help for insurance questions is
            outdated, inefficient, and frustrating. The days of calling the
            number on the back of your insurance card are over.
          </p>
          <p>
            Enter Gibby, we’re here to decode your bills and answer any
            questions you may have about your health insurance. Gibby looks up
            your policy to understand your deductibles, co-insurance, and any
            other costs that services may incur.
          </p>
        </div>
        <div className="card-alt">
          <h1>
            Our vision is to provide instant and accurate information while
            keeping your records secure
          </h1>
          <p>
            {" "}
            No more waiting on brokerages or insurance call centers. No more
            putting off that doctors visit because you’re afraid of the price
            tag.
          </p>
          Gibby provides instant answers to insurance gibberish. 
        </div>
        <Waitlist />
        <Footer />
      </div>
    </div>
  );
};

export default About;
