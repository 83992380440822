import CryptoJS from "crypto-js"; // Import the encryption library

export const encryptionKey =
  "990e8040a0f1631eafcef9a420e6ecc2debde666a1fefd2162316f7dc89e3770";

// Method to encrypt each field in the data object before storing it in Firebase
export const encryptData = (dataObject, encryptionKey) => {
  try {
    // Ensure data is in a valid format for encryption
    if (!dataObject || typeof dataObject !== "object") {
      console.error("Error encrypting data: Input data is not an object.");
      return null;
    }

    // Create a copy of the data object to avoid modifying the original object
    const encryptedData = { ...dataObject };

    // Iterate over each field in the data object
    for (const field in encryptedData) {
                                         // Check if the field is 'id' or if the value is null or undefined
                                         if (
                                           field !== "id" &&
                                           encryptedData[field] !== null &&
                                           encryptedData[field] !== undefined
                                         ) {
                                           // Encrypt the value of each non-null field using AES encryption with the provided key
                                           encryptedData[
                                             field
                                           ] = CryptoJS.AES.encrypt(
                                             String(encryptedData[field]),
                                             encryptionKey
                                           ).toString();
                                         } else if (
                                           encryptedData[field] === null
                                         ) {
                                           // If the value is null, set it to "N/A"
                                           encryptedData[field] = "N/A";
                                         }
                                       }

    return encryptedData;
  } catch (error) {
    console.error("Error encrypting data:", error);
    return null;
  }
};

// Method to decrypt data retrieved from Firebase
export const decryptData = (encryptedData, encryptionKey) => {
  try {
    // Ensure encryptedData and encryptionKey are not null or undefined
    if (!encryptedData || !encryptionKey) {
      console.error("Error decrypting data: Invalid parameters");
      return null;
    }

    // Parse encrypted data back to an object
    const parsedData = encryptedData;

    // Iterate over each field in the parsedData object
    for (const field in parsedData) {
      // Skip decryption if the field is 'id'
      if (field === "id") {
        continue;
      }

      // Check if the value of the field is null
      if (parsedData[field] === null) {
        // If the value is null, set it to "N/A"
        parsedData[field] = "N/A";
      } else {
        // Otherwise, attempt decryption
        const decryptedValue = CryptoJS.AES.decrypt(
          parsedData[field],
          encryptionKey
        ).toString(CryptoJS.enc.Utf8);

        // Update the field value with the decrypted value if it's not empty
        parsedData[field] = decryptedValue || null;
      }
    }

    return parsedData;
  } catch (error) {
    console.error("Error decrypting data:", error);
    return null;
  }
};

// fix: delete bug

